import axios from "axios";
import { toast } from "react-hot-toast";
import store from "../store";
import { logout } from "../store/slices/auth-slice";
const api = axios.create({
  baseURL: process.env.REACT_APP_TERRORISM_SERVER,

  headers: { "X-Custom-Header": "foobar" },
});

api.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem("token");
    const pathname = window.location.pathname;
    config.headers.originPath = pathname;

    config.headers.authorization = `Bearer ${accessToken}`;

    return config;
  },
  (error) => {
    return error;
  }
);

api.interceptors.response.use(
  (response) => {
    const { data } = response;
    if (data.notify) {
      const customId =
        data.message +
          data?.code +
          new Date()
            .toISOString()
            .replace(/[-T:\.Z]/g, "")
            .slice(0, 14) ?? "success";
      toast.success(data.message ?? "success", { toastId: customId });
    }
    return response.data;
  },
  (error) => {
    const { response } = error;
    const customId =
      response?.data.message +
        response?.data?.code +
        new Date()
          .toISOString()
          .replace(/[-T:\.Z]/g, "")
          .slice(0, 14) ?? "success";
    if (response.data.notify === true && response.status === 401) {
      toast.error(`(401) ${response?.data?.message}`, { toastId: customId });
      store.dispatch(logout());
      localStorage.clear();
    } else if (response.data.notify === true) {
      console.log("=========== on error ", response);
      toast.error(response?.data?.message);
    }

    return error;
  }
);
export default api;
