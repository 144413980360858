const Endpoints = {
  login: "auth/login",
  verifyOtp: "auth/verify-otp",
  forgotPwd: "auth/forget-password",
  resetPwd: "auth/reset-password",
  // general
  globalSearch: "search/query",
  removeMedia: "gen/remove-media",
  updateProfile: "gen/update-profile",
  updatePassword: "gen/update-password",
  heatMapData: "gen/heat-map",
  totalIncidentStats: "gen/total-incidents",
  totalPersonStats: "gen/total-persons",
  totalOrgStats: "gen/total-orgs",
  totalWeaponStats: "gen/total-weapons",
  incidentStatsByType: "gen/incident-by-type",
  incidentStatsByTrends: "gen/incident-by-trends",
  weaponUsed: "gen/weapon-used",
  threatLevelByTime: "gen/threat-level-over-time",
  networkGraph: "gen/network-graph",
  orgInvolvementOverRegion: "gen/org-involvement",
  incidentsByPlace: "gen/incidents-on-map",
  threatLevelBarChart: "gen/threat-level-bar-chart",
  // notifications
  notiCount: "gen/noti-counter",
  notiList: "gen/notifications-in-dropdown",
  allNotiList: "gen/notifications",
  seeNOtification: "gen/see-noti",
  // user management
  getUsers: "user/get-users",
  getUser: "user/get-user",
  userRoles: "user/roles",
  createUser: "user/create-user",
  updateUser: "user/update-user",
  removeUser: "user/remove-user",
  // organization management
  orgCreate: "organization/create-organization",
  orgEdit: "organization/update-organization",
  getAllOrgs: "organization/get-organizations",
  getSingleOrg: "organization/view-organization",
  removeOrg: "organization/remove-organization",
  //  weapon management
  getWeaponTypes: "weapon/get-weapon-types",
  createWeapon: "weapon/create-weapon",
  updateWeapon: "weapon/update-weapon",
  getSingeWeapon: "weapon/view-weapon",
  getAllWeapons: "weapon/get-weapons",
  removeWeapon: "weapon/remove-weapon",
  // address management
  getAllAddress: "organization/get-organizations-with-addresses",
  getSingleAddress: "organization/view-addresses",
  updateOrgAddress: "organization/update-addresses",
  // person management
  getAllPersons: "person/get-persons",
  findPerson: "person/find-person",
  getPerson: "person/view-person",
  personLevels: "person/get-person-levels",
  addPerson: "person/create-person",
  updatePerson: "person/update-person",
  removePerson: "person/remove-person",
  orgList: "incident/get-organizations",
  personList: "incident/persons",
  // incidents management
  addIncident: "incident/add-incident",
  updateIncident: "incident/update-incident",
  getAllIncident: "incident/list-incidents",
  viewIncident: "incident/view-incident",
  removeIncident: "incident/remove-incident",
  // attacks types management
  attackTypesList: "incident/get-attack-types",
  // threat levels
  threatLevels: "incident/get-threat-levels",
  // Evidence management
  incidentList: "/evidence/incidents-list",
  evidenceCat: "evidence/categories",
  evidenceStatus: "evidence/statuses",
  addEvidence: "evidence/add",
  updateEvidence: "evidence/update",
  viewEvidence: "evidence/view",
  getAllEvidences: "evidence/list",
  removeEvidence: "evidence/remove",
  // support
  generateTicket: "support/generate-ticket",
  getAllTickets: "support/threads",
  sendMessage: "support/send-message",
  getAllMessages: "support/messages",
  updateTicketStatus: "support/update-status",
};
export default Endpoints;
