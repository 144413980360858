import React, { useEffect, useRef, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { hideSearchPopUP } from "../../store/slices/ui-slice";
import { useTheme } from "@emotion/react";
import {
  CircularProgress,
  DialogContent,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";

import InputField from "../inputField/InputField";
import { Box } from "@mui/system";
import { Search, ShortText } from "@mui/icons-material";
import { useNavigate } from "react-router";
import api from "../../api";
import Endpoints from "../../api/Endpoints";
import { SearchIcon } from "../../assets/images";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ElasticSearch = () => {
  const [showModal, setShowModal] = useState(false);
  const debounceTimeoutRef = useRef(null);
  const inputRef = useRef(null);

  const [searchResults, setSearchResults] = useState([]);
  const [abortController, setAbortController] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [navigating, setNavigating] = useState(false);
  const [filters, setFilters] = useState({
    limit: 10,
    page: 1,
  });

  const { showSearchPopUP } = useSelector((state) => state.ui);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = () => {
    dispatch(hideSearchPopUP());
    setSearchResults([]);
    setSearchValue("");
    if (abortController) {
      abortController.abort();
    }
  };

  // Simulate an async search function
  const search = async (query) => {
    try {
      setLoading(true);
      const controller = new AbortController();
      setAbortController(controller);
      const res = await api.get(
        `${Endpoints.globalSearch}?search=${query}&limit=10&page=1`,
        {
          signal: controller.signal,
        }
      );
      setSearchResults(res?.data?.list);
    } catch (e) {
      console.log("============ error in search", e);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(async () => {
      // if (value && value.length >= 3) {
      // if (value && value.length >= 3) {
      if (value) {
        search(value);
      } else {
        setSearchResults([]);
      }
    }, 1000);
  };

  const handleNavigation = (key, value) => {
    setNavigating(true); // set navigating to true

    switch (key) {
      case "persons":
        navigate(`/persons/view/${value?.person_id}`);
        break;
      case "organizations":
        navigate(`/organizations/view/${value?.org_id}`);
        break;
      case "organization_addresses":
        navigate(`/organizations/view/${value?.org_id}`);
        break;
      case "incidents":
        navigate(`/incidents/view/${value?.incident_id}`);
        break;
      case "weapons":
        navigate(`/weapons/view/${value?.weapon_id}`);
        break;
      case "evidence":
        navigate(`/evidence/view/${value?.evidence_id}`);
        break;
      default:
        break;
    }
    // Close the modal first
    handleClose();
  };

  const getDataToShow = (key, value) => {
    let dataToShow = {
      title: "",
      description: "",
      shortDetails: "",
    };

    switch (key) {
      case "persons":
        dataToShow = {
          title: "Person",
          description: value?.name || "",
          shortDetails: value?.nationality || "",
        };
        break;
      case "organizations":
        dataToShow = { title: "Organization", description: value?.name || "" };
        break;
      case "organization_addresses":
        dataToShow = {
          title: "Organization",
          description: value?.address || "",
        };
        break;
      case "incidents":
        dataToShow = {
          title: "Incident",
          description: value?.name || "",
          shortDetails: value?.location || "",
        };
        break;
      case "weapons":
        dataToShow = { title: "Weapon", description: value?.weapon_name || "" };
        break;
      case "evidence":
        dataToShow = { title: "Evidence", description: value?.item || "" };
        break;
      default:
        break; // This handles any other unexpected key
    }

    return dataToShow; // Return after the switch block
  };

  useEffect(() => {
    if (showSearchPopUP) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [showSearchPopUP]);

  return (
    <Dialog
      open={showModal}
      TransitionComponent={Transition}
      // fullScreen={fullScreen}

      onClose={handleClose}
      // aria-describedby="alert-dialog-slide-description"
      sx={{
        "& .MuiDialog-paper": {
          padding: "10px",
          background: "primary.whiteCard",
          borderRadius: "8px",
          width: "600px", // Correct selector for Dialog
        },
      }}
    >
      <Box>
        <InputField
          value={searchValue}
          placeholder="What are you looking for?"
          startAdornment={
            loading ? (
              <CircularProgress size={24} sx={{ color: "primary.main" }} />
            ) : (
              <Box
                component="img"
                src={SearchIcon}
                sx={{
                  // filter: "brightness(2.5)",
                  height: 30,
                }}
              />
            )
          }
          name="search"
          // type="search"
          onChange={handleInputChange}
          endAdornment={
            <Box>
              <IconButton
                onClick={handleClose}
                sx={{
                  fontSize: "12px",
                  borderRadius: "4px",
                  border: "1px solid #00000036",
                  backgroundColor: "#0000000f",
                }}
              >
                esc
              </IconButton>
            </Box>
          }
        />
      </Box>

      <Box
        sx={{
          mt: "1rem",
          maxHeight: "500px",
          overflowY: "auto",
          px: ".5rem",
        }}
      >
        {Object.entries(searchResults).map(([parentKey, child], ind) => {
          return (
            <>
              {child && (
                <Typography
                  variant="h3"
                  sx={{
                    textTransform: "capitalize",
                    mb: "1rem",
                    mt: ind > 0 ? "1rem" : "0",
                  }}
                >
                  {parentKey?.replace(/_/g, " ")}
                </Typography>
              )}
              {child &&
                child.length > 0 &&
                child?.map((item, index) => {
                  return (
                    <ListItemButton
                      sx={{
                        border: "1px solid #00000036",
                        mb: "5px",
                        borderRadius: "8px",
                      }}
                      key={index}
                      onClick={() => {
                        handleNavigation(parentKey, item);
                      }}
                    >
                      <ListItemText
                        // primary={
                        //   <Typography sx={{ textTransform: "capitalize" }}>
                        //     {getDataToShow(parentKey, item)?.title}
                        //   </Typography>
                        // }
                        primary={
                          <Typography
                            sx={{
                              fontSize: "12px",
                              color: "primary.blueText",
                            }}
                          >
                            {getDataToShow(parentKey, item)?.description}
                          </Typography>
                        }
                        secondary={
                          getDataToShow(parentKey, item)?.shortDetails && (
                            <Typography
                              sx={{
                                fontSize: "12px",
                                color: "primary.grayText",
                              }}
                            >
                              {getDataToShow(parentKey, item)?.shortDetails}
                            </Typography>
                          )
                        }
                      />
                    </ListItemButton>
                  );
                })}
            </>
          );
        })}
      </Box>
    </Dialog>
  );
};

export default ElasticSearch;
