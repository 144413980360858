import React, { useEffect } from "react";
import Header from "./Header/Header";
import Sidebar from "./Sidebar";
import { Outlet } from "react-router";
import { Box, Drawer } from "@mui/material";
import useWindowResize from "../hooks/useWindowResize";
import { useDispatch, useSelector } from "react-redux";
import {
  hideSearchPopUP,
  showSearchPopUP,
  toggleSideBar,
} from "../store/slices/ui-slice";
import useMediaQuery from "@mui/material/useMediaQuery";
import ElasticSearch from "../components/elastic-search/ElasticSearch";

const Layout = () => {
  const windowWidth = useWindowResize();
  const isTab = useMediaQuery("(max-width:900px)");
  const { showSideBar } = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  const handleSearchPopUp = () => {
    dispatch(showSearchPopUP());
  };

  // Effect to listen for "Ctrl + K"
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "k") {
        event.preventDefault(); // Prevent default browser behavior
        handleSearchPopUp();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      <header>
        <Header />
      </header>
      <Box sx={{ display: "flex", mt: "64px" }}>
        {!isTab ? (
          <Box
            sx={{
              width: "240px",
              backgroundColor: "primary.whiteCard",
              pt: "1.5rem",
              height: "calc(100vh - 85px)",
              position: "fixed",
            }}
          >
            <Sidebar />
          </Box>
        ) : (
          <Drawer
            open={showSideBar}
            anchor="left"
            onClose={() => dispatch(toggleSideBar())}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              "& .MuiDrawer-paper": {
                pt: "2rem",
                width: "240px",
                top: { xs: "55px", sm: "64px" }, // adjust this value to match the height of your app bar
                height: "calc(100vh - 64px)", // adjust this value to match the height of your app bar
              },
            }}
          >
            <Sidebar />
          </Drawer>
        )}

        <Box
          pt="2.25rem"
          sx={{
            width: { xs: "100%", md: "calc(100% - 240px)" },
            marginLeft: { md: "240px" },
            px: { xs: "1rem", md: "2rem" },
            overflowX: "hidden",
          }}
        >
          <Outlet />
        </Box>
      </Box>
      <ElasticSearch />
    </>
  );
};

export default Layout;
