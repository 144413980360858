import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showSideBar: false,
  noti_refresh: false,
  showSearchPopUP: false,
};

const UISlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    toggleSideBar(state) {
      state.showSideBar = !state.showSideBar;
    },
    showSearchPopUP(state) {
      state.showSearchPopUP = true;
    },
    hideSearchPopUP(state) {
      state.showSearchPopUP = false;
    },
    setNotiRefresh(state) {
      state.noti_refresh = !state.noti_refresh;
    },
  },
});

export const {
  toggleSideBar,
  setNotiRefresh,
  showSearchPopUP,
  hideSearchPopUP,
} = UISlice.actions;
export default UISlice.reducer;
