import React from "react";
// import "./style.css";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {
  FormHelperText,
  InputAdornment,
  TextField,
  Typography,
  TextareaAutosize,
} from "@mui/material";

const BootstrapInput = styled(TextField)(({ theme, multiline, height,backgroundColor,borderRadius }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-root": {
    borderRadius: borderRadius,
    position: "relative",
    backgroundColor: backgroundColor,
    height: height,
    fontSize: 16,
    transition: theme.transitions.create(["background-color", "box-shadow"]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.2)} 0 0 0 0.2rem`,
    },
    "& fieldset": {
      border: `1px solid ${theme.palette.primary.inputBorder}`,
    },
    "& .MuiInputBase-input": {
      height: !multiline && "8px",
    },
  },
}));

const StyledTextareaAutosize = styled(TextareaAutosize)(({ theme, }) => ({
  width: "100%",
  borderRadius: "8px",
  border: `1px solid ${theme.palette.primary.inputBorder}`,
  // backgroundColor: "#F1F4FA",
  fontSize: 16,
  // padding: "8px 3px",
  paddingLeft:'6px',
  resize: "vertical",
  overflow: "auto",
  transition: theme.transitions.create(["background-color", "box-shadow"]),
  "&:focus": {
    boxShadow: `${alpha(theme.palette.primary.main, 0.2)} 0 0 0 0.2rem`,
    outline: "none",
  },
  // border: "none",
}));

function InputField({
  required,
  label,
  bgColor,
   backgroundColor="",
   borderRadius="8px",
  isCustomBorder = false,
  multiline = false,
  minRows = 1,
  maxRows = 2,
  onChange = null,
  formik,
  name,
  startAdornment = null,
  endAdornment = null,
  width = "100%",
  height = "50px",
  placeHolder="",
  ...props
}) {
  return (
    <FormControl variant="standard" sx={{ width: "100%" }}>
      {label && (
        <Typography
          variant="h6"
          sx={{
            color: "primary.blackText",
            fontSize: "14px",
            fontWeight: "600",
            mb: "10px",
          }}
        >
          {label}
          {required && (
            <span
              style={{
                color: "red",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              *
            </span>
          )}
        </Typography>
    )}  
      {multiline ? (
        <StyledTextareaAutosize
          minRows={minRows}
          maxRows={maxRows}
          name={name}
          value={formik ? formik.values[name] : props.value}
          onChange={
            onChange ? (e) => onChange(e) : (e) => formik.handleChange(e)
          }
          onBlur={formik && formik.handleBlur}
          backgroundColor={backgroundColor}
          borderRadius={borderRadius}
          style={{
            border: 'primary.inputBorder',
            borderRadius: '8px',
            width: { xs: "100%", lg: width },
            height: "120px",
          }}
          {...props}
        />
      ) : (
        <BootstrapInput
          multiline={multiline}
          height={height}
          rows={props.rows}
          className={`${props.className}`}
          type={props.type}
          name={name}
          placeHolder={placeHolder}
          value={formik ? formik.values[name] : props.value}
          // onChange={
          //   onChange ? (e) => onChange(e) : (e) => formik.handleChange(e)
          // }
          onChange={(e) => {
            if (onChange) {
              if (props.type === "date" && e.target.value.length > 10) {
                return;
              }
              onChange(e);
            } else {
              if (props.type === "date" && e.target.value.length > 10) {
                return;
              }
              formik.handleChange(e);
            }
          }}
          onBlur={formik && formik.handleBlur}
          backgroundColor={backgroundColor}
          borderRadius={borderRadius}
          sx={{
            // border: isCustomBorder ? "2px solid #A7A7A7" : "",
            // borderRadius: isCustomBorder ? "3px" : "10px",
            width: { xs: "100%", lg: width },
            backgroundColor,
          }}
          InputProps={{
            startAdornment: startAdornment && (
              <InputAdornment position="start">{startAdornment}</InputAdornment>
            ),
            endAdornment: endAdornment && (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ),
            sx: {
              "& .MuiInputBase-input": {
                height: !multiline && "8px",
              },
            },
          }}
          {...props}
        />
      )}
      {formik && formik.touched[name] && formik.errors[name] && (
        <FormHelperText sx={{ color: "red", pl: "10px" }}>
          {formik.errors[name]}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default InputField;
