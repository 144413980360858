import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import uiSlice from "./slices/ui-slice";
import authReducer from "./slices/auth-slice";
import chatSlice from "./slices/chat-slice";
const authPersistConfig = {
  key: "auth",
  storage,
};

const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);

const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    ui: uiSlice,
    chat: chatSlice,
    // other reducers...
  },
});

export const persistor = persistStore(store);

export default store;
