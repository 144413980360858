import { useState, useCallback, useEffect, useRef } from "react";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";

import api from "../../../api";
import Endpoints from "../../../api/Endpoints";
import { LightNotificationIcon, noNotifImg } from "../../../assets/images";

import { useSelector } from "react-redux";

export default function NotificationMenu({ data = [], sx, ...other }) {
  const timeRef = useRef(null);
  const { noti_refresh } = useSelector((state) => state.ui);
  const [notifications, setNotifications] = useState(data);
  const [totalUnRead, setTotalUnRead] = useState(0);
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(null);

  const handleOpenMenu = useCallback((event) => {
    getNotifications();
    setOpenMenu(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setOpenMenu(null);
  }, []);

  const handleNotiClick = (notification) => {
    handleCloseMenu();
    timeRef.current = setTimeout(
      () => navigate(`/notifications/view/${notification.id}`),
      100
    );
  };

  const handleViewAll = () => {
    handleCloseMenu();
    timeRef.current = setTimeout(() => navigate(`/notifications`), 100);
  };

  const getNotifications = async () => {
    setLoading(true);
    try {
      const res = await api.get(`${Endpoints.notiList}`);
      setNotifications(res?.data);
    } catch (e) {
      console.error("Error fetching notifications", e);
    } finally {
      setLoading(false);
    }
  };

  const getTotalCount = async () => {
    try {
      const res = await api.get(`${Endpoints.notiCount}`);
      setTotalUnRead(res?.data?.unreadCount);
    } catch (e) {
      console.error("Error fetching unread count", e);
    }
  };

  useEffect(() => {
    getTotalCount();
    return clearTimeout(timeRef.current);
  }, [pathname, noti_refresh]);

  return (
    <>
      <IconButton onClick={handleOpenMenu} sx={sx} {...other}>
        <Badge badgeContent={totalUnRead} color="error">
          <Box component="img" src={LightNotificationIcon} />
        </Badge>
      </IconButton>

      <Menu
        open={!!openMenu}
        anchorEl={openMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            width: 360,
            mt: 1.5,
            backgroundColor: "white",
            borderRadius: "8px",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          sx={{ py: 2, pl: 2.5, pr: 1.5 }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h3">Notifications</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Box sx={{ maxHeight: 250, overflowY: "auto" }}>
          {notifications.length > 0 ? (
            <List disablePadding>
              {notifications.map((notification) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                  handleNotiClick={handleNotiClick}
                />
              ))}
            </List>
          ) : (
            <Box
              sx={{
                height: "100%",
                alignContent: "center",
                textAlign: "center",
                py: "2rem",
              }}
            >
              <Box
                component="img"
                src={noNotifImg}
                sx={{ height: "150px", width: "150px" }}
              />
            </Box>
          )}
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth color="inherit" onClick={handleViewAll}>
            View all
          </Button>
        </Box>
      </Menu>
    </>
  );
}

function NotificationItem({ notification, handleNotiClick }) {
  return (
    <ListItemButton
      sx={{
        px: 2.5,
        mt: "1px",
        ...(notification.isUnRead && {
          bgcolor: "action.selected",
        }),
      }}
      onClick={() => handleNotiClick(notification)}
    >
      <ListItemText
        primary={<Typography variant="h3">{notification?.title}</Typography>}
        secondary={
          <Typography
            variant="caption"
            sx={{
              display: "flex",
              alignItems: "center",
              color: "primary.blueText",
            }}
          >
            {moment(notification?.createdAt).fromNow()}
          </Typography>
        }
      />
    </ListItemButton>
  );
}
